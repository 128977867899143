import { useState, useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import { getCustomerToken } from '@services/shopify/customer-token';

const RouteGuard = ({ children }) => {
	const router = useRouter();

	const CustomerToken = getCustomerToken();

	const [authorized, setAuthorized] = useState(false);

	const authCheck = useCallback(
		url => {
			const publicPaths = ['/login', '/register'];
			const path = url.split('?')[0];

			if (!CustomerToken && !publicPaths.includes(path)) {
				setAuthorized(false);
				router.push({
					pathname: '/account/login',
					query: { returnUrl: router.asPath },
				});
			} else {
				setAuthorized(true);
			}
		},
		[CustomerToken, router]
	);

	useEffect(() => {
		// on initial load - run auth check
		authCheck(router.asPath);

		// on route change start - hide page content by setting authorized to false
		const hideContent = () => setAuthorized(false);
		router.events.on('routeChangeStart', hideContent);

		// on route change complete - run auth check
		router.events.on('routeChangeComplete', authCheck);

		// unsubscribe from events
		return () => {
			router.events.off('routeChangeStart', hideContent);
			router.events.off('routeChangeComplete', authCheck);
		};
	}, [authCheck, router.asPath, router.events]);

	return authorized && children;
};

export default RouteGuard;
