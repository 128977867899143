import styles from './YotpoWidget.module.scss';

const YotpoWidget = ({ widgetId, injectCustomCSS = true }) => {
	return (
		<div className={injectCustomCSS && styles.widget}>
			<div className='yotpo-widget-instance' data-yotpo-instance-id={widgetId} />
		</div>
	);
};

export default YotpoWidget;
