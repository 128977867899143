import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

function YellowStar() {
	return (
		<AccessibleIcon.Root label='star'>
			<img alt='yellow star' src='/YellowStar.png' width={46} height={46} />
		</AccessibleIcon.Root>
	);
}

export default YellowStar;
