import { Customer } from '@ts/shopify-storefront-api';
import { getShopifyIdFromGID } from '@utils/shopify';

/** Exposes appropriate customer information so that Yotpo widgets dislpay accurate rewards */
const CustomerIdElement = ({ customer }: { customer: Customer }) => {
	if (!customer) return null;
	return (
		<div
			id='swell-customer-identification'
			data-authenticated='true'
			data-email={customer.email}
			data-id={getShopifyIdFromGID(customer.id)}
			data-tags={customer.tags.join(' ')}
			style={{ display: 'none' }}
		></div>
	);
};

export default CustomerIdElement;
